import { Button, Input } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SearchInputProps {
  map: google.maps.Map
  onPlaceSelected: (placeLatLng: google.maps.LatLngLiteral) => void
}

const delay = 1000

const PlacesAutocomplete: FC<SearchInputProps> = ({ map, onPlaceSelected }) => {
  const { t } = useTranslation()
  const [searchCriteria, setSearchCriteria] = useState('')
  const [places, setPlaces] = useState<google.maps.places.AutocompletePrediction[]>([])
  const autocompleteService = useMemo(() => new google.maps.places.AutocompleteService(), [])
  const placesService = useMemo(() => (map ? new google.maps.places.PlacesService(map) : null), [map])
  const [showPlaces, setShowPlaces] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { language } = useContext(I18nextContext)

  useEffect(() => {
    if (searchCriteria?.length < 3) {
      return () => {}
    }
    const handler = setTimeout(() => {
      autocompleteService.getPlacePredictions(
        {
          input: searchCriteria,
          origin: map.getCenter(),
          componentRestrictions: {
            country: 'ES',
          },
          language,
        },
        (predictions, status) => {
          setPlaces(status !== google.maps.places.PlacesServiceStatus.OK ? [] : predictions)
        },
      )
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [autocompleteService, map, searchCriteria, language])

  const handleChange = useCallback((e) => {
    setPlaces([])
    setSearchCriteria(e.target.value)
  }, [])

  const getPlaceLatLng = useCallback(
    (place: google.maps.places.AutocompletePrediction) => {
      placesService.getDetails({ placeId: place.place_id }, (placeDetail, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          inputRef.current.value = place.description
          setShowPlaces(false)
          onPlaceSelected({ lat: placeDetail.geometry.location.lat(), lng: placeDetail.geometry.location.lng() })
        }
      })
    },
    [onPlaceSelected, placesService],
  )

  useEffect(() => {
    setShowPlaces(places?.length > 0 && searchCriteria !== '')
  }, [places, searchCriteria])

  return (
    <div className="places-autocomplete">
      <Input
        icon="search"
        type="text"
        placeholder={t('cashiers.placeholder')}
        label={t('cashiers.label')}
        onInput={handleChange}
        forwardRef={inputRef}
        onFocus={() => inputRef?.current?.select()}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setShowPlaces(false)
          }
          if (e.key === 'Enter' && places?.length > 0) {
            getPlaceLatLng(places[0])
          }
        }}
      />
      {showPlaces && (
        <div className="lk-header__searcher-drawer">
          {places?.map((place) => (
            <Button
              type="button"
              buttonType="compact"
              buttonRole="secondary"
              onClick={() => getPlaceLatLng(place)}
              key={place.place_id}
              className="lk-header__searcher-link"
            >
              {place.description}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

export default PlacesAutocomplete
