import React, { FC, useState } from 'react'

import SanitySchema from '../../../lib/types/sanity-schema'
import Figure from '../../Figure/Figure'
import LkPortableText from '../../PortableText/PortableText'
import OfficesProps from './OfficesInterface'
import OfficesList from './OfficesList'
import OfficesMap from './OfficesMap'
import OfficesSearchBar from './OfficesSearchBar'

export interface OfficesWithInfoCardProps extends OfficesProps {
  infoCard: SanitySchema.TextWithIllustration
}

const Offices: FC<OfficesWithInfoCardProps> = ({ offices, infoCard }) => {
  const [searchOfficesResult, setSearchOfficesResult] = useState(offices)
  const [selectedOffice, setSelectedOffice] = useState()

  const searchOffices = (searchCriteria: string): OfficesProps => {
    if (searchCriteria.length > 0) {
      setSearchOfficesResult(
        offices.filter((office) =>
          (office.region + office.city + office.address_full + office.zipcode)
            .toLowerCase()
            .includes(searchCriteria.toLowerCase()),
        ),
      )
    } else {
      setSearchOfficesResult(offices)
    }
    return { offices }
  }

  return (
    <div>
      <OfficesSearchBar searchAction={searchOffices} />
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-md-6 col-sm-12">
            <OfficesMap offices={searchOfficesResult} selectedOffice={selectedOffice} />
          </div>
          {infoCard && (
            <div className="col-xl-5 col-md-6 col-sm-12">
              <article className="lk-offices-card d-flex">
                {infoCard.illustration.image ? (
                  <Figure
                    node={infoCard.illustration.image as SanitySchema.MainImage}
                    args={{ width: 100 }}
                    className="lk-offices-card__figure"
                  />
                ) : null}
                {infoCard.text ? (
                  <div className="lk-offices-card__content">
                    <div className="lk-offices-card__body" data-testid="lk-offices-card__body">
                      <LkPortableText blocks={infoCard.text} />
                    </div>
                  </div>
                ) : null}
              </article>
            </div>
          )}
        </div>
      </div>
      <OfficesList offices={searchOfficesResult} onOfficeClick={(office) => setSelectedOffice(office)} />
    </div>
  )
}

export default Offices
