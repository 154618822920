const whiteList = [
  'Laboral Kutxa',
  'Grupo Caja Rural',
  'Caja Rural de Albal',
  'Caja Rural Granada',
  'Caja Rural San José de Alcora',
  'Globalcaja',
  'Caja Rural de Algemesí',
  'Caja Rural de Jaén',
  'Caja Rural San José de Almassora',
  'Caixa Rural de L´Alcúdia',
  'Caja Rural de Aragón',
  'Caja Rural de Navarra',
  'Caja Rural de Asturias',
  'Caja Rural Nuestra Señora de la Esperanza de Onda',
  'Ruralnostra',
  'Caixa Popular',
  'Cajaviva',
  'Caja Rural Regional San Agustín',
  'Caja Rural de Casas Ibáñez',
  'Caja Rural de Salamanca',
  'Caja Rural Central',
  'Caja Rural de Soria',
  'Caja Rural San Isidro de les Coves de Vinromà',
  'Caja Rural del Sur',
  'Caja Rural de Extremadura',
  'Cajasiete',
  'Caixa Rural Galega',
  'Caja Rural de Teruel',
  'Caja Rural de Gijón',
  'Caja Rural de Villamalea',
  'Caixa Vinaròs',
  'Caja Rural de Zamora',
  'Grupo Cajamar: ',
  'Banco de Crédito Cooperativo (entidad cabecera)',
  'Cajamar Caja Rural',
  'Caixa Rural Torrent',
  'Caixa Rural de Vila-real',
  'Caixaltea',
  'Caixa Rural Burriana',
  'Caixa Rural Nules',
  'Caixacallosa',
  'Caixapetrer',
  'Caixaalqueries',
  "Caixa Rural San Vicent Ferrer de Vall d'Uixó",
  'Caja Rural de Cheste',
  "Caixa Rural d'Alginet",
  'Caja Rural de Villar',
  'Caixaturís',
  'Caixa Rural Vilavella',
  'Caixa Rural de Almenara',
  'Caixa Rural Vilafamés',
  'Caixa Rural Xilxes.',
  'Bankinter',
  'Caja ingenieros',
  'Caja de Arquitectos',
  'Cashzone',
  'Deutschebank',
  'Euro Automatic Cash',
  'Targo Banka',
  'Euronet',
].map((bank) => bank.toLowerCase())

const blackList = [
  'BBVA',
  'Caixabank',
  'Kutxabank',
  'Santander',
  'ING',
  'Banco Santander',
  'Banco Sabadell',
  'Abanca',
  'Triodos Bank',
  'Openbank',
  'Evo Banco',
  'Bankia',
  'Bankoa',
  'Ibercaja',
  'Unicaja',
  'Liberbank',
  'Cajasur',
  'BBVA',
  'Caixabank',
  'Kutxabank',
  'Santander',
  'ING',
  'Banco Santander',
  'Banco Sabadell',
  'Abanca',
  'Triodos Bank',
  'Openbank',
  'Evo Banco',
  'Bankia',
  'Bankoa',
  'Ibercaja',
  'Unicaja',
  'Liberbank',
  'Cajasur',
].map((bank) => bank.toLowerCase())

export interface Cashier extends Omit<google.maps.places.PlaceResult, 'geometry'> {
  isLk: boolean
  geometry: {
    location: {
      lat: number
      lng: number
    }
  }
}

const useCashiersUtils = () => ({
  filterCashiers: (cashiers: Cashier[], showAll = false): Cashier[] => {
    const whiteListed: Cashier[] = cashiers.filter((c) =>
      whiteList.some((white) => c.name?.toLocaleLowerCase().includes(white)),
    )

    let others: Cashier[] = []
    if (showAll) {
      others = cashiers.filter((c) => !whiteList.some((name) => c.name?.toLocaleLowerCase().includes(name)))
    }
    return [...others, ...whiteListed]
  },

  directionsUrl: (lat: number, lng: number) =>
    /iPhone|iPad|iPod/i.test(window?.navigator?.userAgent)
      ? `https://maps.apple.com/?daddr=${lat},${lng}`
      : `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
})

export default useCashiersUtils
