import { InfoWindow, Marker, MarkerProps } from '@react-google-maps/api'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import OtherIcon from '../../../images/Components/OfficesMap/markerAtmOtherIcon.png'
import AtmSecondaryIcon from '../../../images/Components/OfficesMap/markerAtmSecondaryIcon.png'
import LkIcon from '../../../images/Components/OfficesMap/markerIcon.png'
import useCashiersUtils, { Cashier } from './useCashiersUtils'

export interface CashierMarkerProps extends Omit<MarkerProps, 'position'> {
  place: Cashier
  isSelected
  onPlaceSelected: (c: Cashier) => void
}

const CashierMarker: FC<CashierMarkerProps> = ({ place, onPlaceSelected, isSelected, ...otherProps }) => {
  const { t } = useTranslation()
  const { directionsUrl: howToGetUrl } = useCashiersUtils()
  let icon = OtherIcon

  icon = place.isLk ? LkIcon : AtmSecondaryIcon

  return (
    <Marker
      key={`marker-${place.place_id}`}
      position={place.geometry?.location || { lat: 0, lng: 0 }}
      title={place.name}
      icon={icon}
      onClick={() => onPlaceSelected(place)}
      zIndex={place.isLk ? 999999 : 1}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {isSelected && (
        <InfoWindow onCloseClick={() => onPlaceSelected(null)} options={{ disableAutoPan: true }}>
          <div className="cashiers-infoWindow">
            <div>
              <strong>{place.name}</strong>
            </div>
            <div className="cashiers-infoWindow__addr">{place.formatted_address}</div>
            <div className="cashiers-infoWindow__link">
              <a href={howToGetUrl(place.geometry?.location?.lat, place.geometry?.location?.lng)}>
                {t('cashiers.howToGet')}
              </a>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  )
}

export default CashierMarker
