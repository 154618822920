import './OfficesList.scss'

import { Button, Table } from '@lk/lk-design-system'
import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Business } from '../../../services/business-service'
import OfficesProps from './OfficesInterface'

export interface OfficesListProps extends OfficesProps {
  onOfficeClick(office): void
}

const OfficesList: FC<OfficesListProps> = ({ offices, onOfficeClick }) => {
  const { t } = useTranslation()
  const mediaQuerySM = 599
  const customStyles = {
    rows: {
      style: {
        cursor: 'pointer',
      },
    },
  }

  const onOfficeSelected = useCallback(
    (row) => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      onOfficeClick?.(row)
    },
    [onOfficeClick],
  )

  useEffect(() => {
    const headers = document?.getElementsByClassName('rdt_TableCol_Sortable')
    for (let i = 0; i < headers.length; i += 1) {
      headers[i].removeAttribute('tabIndex')
    }
  }, [])

  const cityCell = useCallback(
    (row) => (
      <div data-tag="allowRowEvents" id={`cell-${row.id}`}>
        <p className="offices-list-only-sm">
          <Button
            icon="map-pin"
            buttonType="compact"
            buttonRole="secondary"
            aria-label={t('officesList.findInMap')}
            onClick={() => onOfficeSelected(row)}
          />
          <strong data-tag="allowRowEvents">
            {String(row.code).padStart(4, '0')} - {row.name}
          </strong>
        </p>
        <p data-tag="allowRowEvents">{`${row.address_full} (${row.city})`}</p>
        <div className="offices-list-only-sm" data-tag="allowRowEvents">
          {row.contacts.map((c) => (
            <p key={c.phone_numbers.join('')}>Tlf: {c.phone_numbers.join(' / ')}</p>
          ))}
        </div>
      </div>
    ),
    [onOfficeSelected, t],
  )

  const openHoursCell = useCallback(
    (row) => (
      <div data-tag="allowRowEvents">
        {row.open_hours.map((h) => (
          <p key={h} data-tag="allowRowEvents">
            {h}
          </p>
        ))}
      </div>
    ),
    [],
  )

  const contactCell = useCallback(
    (row) => (
      <div data-tag="allowRowEvents">
        {row.contacts.map((c) => (
          <p key={c.phone_numbers.join('')} data-tag="allowRowEvents">
            Tlf: {c.phone_numbers.join(' / ')}
          </p>
        ))}
      </div>
    ),
    [],
  )

  const iconCell = useCallback(
    (row) => (
      <div className="align-right">
        <Button
          icon="map-pin"
          buttonType="compact"
          buttonRole="secondary"
          aria-label={t('officesList.findInMap')}
          onClick={() => onOfficeSelected(row)}
        />
      </div>
    ),
    [onOfficeSelected, t],
  )

  return (
    <Table
      data-testId="officesTable"
      title="listado de oficinas y cajeros"
      columns={[
        {
          name: t('officesList.number'),
          selector: (b: Business) => String(b.code).padStart(4, '0'),
          wrap: false,
          grow: 0,
          hide: mediaQuerySM,
          sortable: false,
        },
        {
          name: t('officesList.name'),
          selector: (b: Business) => b.name,
          grow: 1.5,
          hide: mediaQuerySM,
          sortable: false,
        },
        {
          name: t('officesList.address'),
          sortField: 'city',
          cell: cityCell,
          grow: 2,
          sortable: false,
        },
        {
          name: t('officesList.openHours'),
          cell: openHoursCell,
          grow: 1,
          sortable: false,
        },
        {
          name: t('officesList.contact'),
          sortField: 'city',
          cell: contactCell,
          grow: 1,
          hide: mediaQuerySM,
          sortable: false,
        },
        {
          name: '',
          cell: iconCell,
          grow: 0,
          hide: mediaQuerySM,
          sortable: false,
        },
      ]}
      noHeader
      data={offices}
      pagination
      paginationServer={false}
      paginationComponentOptions={{ noRowsPerPage: true }}
      paginationPerPage={25}
      sortServer={false}
      striped
      selectableRows={false}
      onRowClicked={(row) => onOfficeSelected(row)}
      customStyles={customStyles}
    />
  )
}
export default OfficesList
