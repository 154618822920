import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api'
import React, { FC, useMemo, useState } from 'react'

import markerIcon from '../../../images/Components/OfficesMap/markerIcon.png'
import { Business } from '../../../services/business-service'
import OfficesProps from './OfficesInterface'

const containerStyle = {
  width: '100%',
  height: '400px',
}

const markerClustererOptions = {
  imagePath: '/Components/OfficesMap/clusterIcon',
  imageSizes: [65],
  gridSize: 65,
  minimumClusterSize: 2,
}

const getInfoWindowContent = (office: Business): JSX.Element => (
  <div>
    <h6>
      <b>{office.name}</b>
    </h6>
    <p>
      <b>{office.address_full}</b>
    </p>
    <p>
      <b>
        {office.region}&nbsp;-&nbsp;{office.zipcode}
      </b>
    </p>
    <span>
      {office.open_hours.map((h) => (
        <p key={h}>{h}</p>
      ))}
    </span>
    <span>{office.contacts.map((contacts) => contacts.phone_numbers.map((h) => <p key={h}>{`☏ ${h}`}</p>))}</span>
  </div>
)

export interface OfficeMapProps extends OfficesProps {
  selectedOffice: Business
}

const OfficeMap: FC<OfficeMapProps> = ({ offices, selectedOffice }) => {
  const [requiredInfoWindowAnchorId, setRequiredInfoWindowAnchorId] = React.useState(null)
  const [zoom, setZoom] = useState(6)
  const center = useMemo(() => {
    setRequiredInfoWindowAnchorId(null)
    let lat = 42.430030130938015
    let lng = -3.7049542105263575
    if (offices?.length) {
      lat = offices[0].lat
      lng = offices[0].long
    }
    if (selectedOffice) {
      lat = selectedOffice.lat
      lng = selectedOffice.long
      setZoom(15)
    }
    return {
      lat,
      lng,
    }
  }, [offices, selectedOffice])

  return (
    <div id="offices-map" data-testid="offices-map">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
        <MarkerClusterer options={markerClustererOptions}>
          {(cluster) =>
            offices.map((office) => {
              const { id, lat, long: lng } = office
              return (
                <Marker
                  position={{ lat, lng }}
                  key={id}
                  onClick={() => setRequiredInfoWindowAnchorId(id)}
                  icon={markerIcon}
                  clusterer={cluster}
                >
                  {requiredInfoWindowAnchorId === id && (
                    <InfoWindow onCloseClick={() => setRequiredInfoWindowAnchorId(null)}>
                      {getInfoWindowContent(office)}
                    </InfoWindow>
                  )}
                </Marker>
              )
            })
          }
        </MarkerClusterer>
      </GoogleMap>
    </div>
  )
}

export default OfficeMap
