import './Offices.scss'

import { Input } from '@lk/lk-design-system'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDebounce from '../../../hooks/useDebounce'
import OfficesProps from './OfficesInterface'

export interface OfficesSearchBarProps {
  searchAction(searchCriteria: string): OfficesProps
}

const OfficesSearchBar: FC<OfficesSearchBarProps> = ({ searchAction }) => {
  const { t } = useTranslation()
  const [searchCriteria, setSearchCriteria] = useState('')
  const searchTerm = useDebounce(searchCriteria, 300)

  const handleSearchCriteriaChange = (event) => setSearchCriteria(event.target.value)

  useEffect(() => {
    searchAction(searchTerm)
  }, [searchAction, searchTerm])

  return (
    <div id="office-search-container" className="container">
      <div id="office-search-controllers-container">
        <Input
          icon="search"
          type="text"
          id="office-search-criteria-input"
          placeholder={t('officesSearchBar.placeholder')}
          name="office-search-criteria-input"
          onChange={handleSearchCriteriaChange}
          label={t('officesSearchBar.label')}
        />
      </div>
    </div>
  )
}

export default OfficesSearchBar
